import React, { FC, memo, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import CustomSelect from '../../custom/CustomSelect';
import CustomMenuItem from '../../custom/CustomMenuItem';
import LabelWrapper from '../../common/LabelWrapper';
import { HydSourceLinks } from '../../../models/inputTypes/HydFields';
import CustomTab from '../../custom/CustomTab';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomInput from '../../custom/CustomInput';
import { sourcePointsArrayPreparation } from '../../../utils/functions/sourcePointsArrayPreparation';
import CustomTabs from '../../custom/CustomTabs';
import { hydSourcePointsObjectInitialState } from '../../../utils/initialStates/hydInputState';
import BoxComponent from '../../custom/BoxComponent';
type InputsProps = {
  inputState: HydSourceLinks;
  setInputState: (value: HydSourceLinks) => void;
};
const HydSourceSinksInputsGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const onDynamicSelectChange = (event: SelectChangeEvent<any>) => {
    const newArray = inputState.source_points.slice();
    newArray[selectedTab] = { ...newArray[selectedTab], [event.target.name]: +event.target.value };
    setInputState({ ...inputState, source_points: newArray });
  };

  const onSelectChange = (event: SelectChangeEvent<any>) => {
    const length = parseInt(event.target.value);
    const arrayBoundary = sourcePointsArrayPreparation(inputState.source_points.slice(), length);
    if ((selectedTab >= length && length > 0) || (length > 0 && selectedTab == -1)) setSelectedTab(0);
    if (length == 0) setSelectedTab(-1);
    setInputState({ ...inputState, pointsLength: event.target.value, source_points: arrayBoundary });
  };

  const onDynamicInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newArray = inputState.source_points.slice();
    newArray[selectedTab] = { ...newArray[selectedTab], [event.target.name]: event.target.value };
    setInputState({ ...inputState, source_points: newArray });
  };

  const sourceTabs = useMemo(() => {
    const newTabs: any[] = [];
    const length = inputState.pointsLength;
    for (let i = 0; i < length; i++) {
      newTabs.push(
        <CustomTab
          id={(i + 1).toString()}
          value={i}
          label={<Typography variant={'subtitle1'}>Point discharge {i + 1}</Typography>}
        />,
      );
    }
    return newTabs;
  }, [inputState.pointsLength]);

  const prepareSelectItems = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, i) => (
        <CustomMenuItem key={i} value={item}>
          <Typography variant={'subtitle2'}>{item}</Typography>
        </CustomMenuItem>
      )),
    [],
  );

  const preparePoints = () => {
    if (!inputState.pointsLength) {
      return (
        <BoxComponent disabled>
          <Box sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
            <Box>
              <LabelWrapper label={'Type'}>
                <CustomSelect disabled value={hydSourcePointsObjectInitialState.point_type}>
                  <CustomMenuItem value={0}>
                    <Typography variant={'subtitle2'}>Source</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={1}>
                    <Typography variant={'subtitle2'}>Sink</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'Grid Cell'}>
                <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                  <CustomInputWithLabel disabled value={hydSourcePointsObjectInitialState.x} label={'X-Axis'} />
                  <CustomInputWithLabel value={hydSourcePointsObjectInitialState.y} disabled label={'Y-Axis'} />
                </Box>
              </LabelWrapper>
            </Box>
          </Box>
          <Box mt={2} sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
            <Box>
              <LabelWrapper label={'Type'}>
                <CustomSelect disabled value={hydSourcePointsObjectInitialState.parameter_type}>
                  <CustomMenuItem value={'1'}>
                    <Typography variant={'subtitle2'}>U & V</Typography>
                  </CustomMenuItem>
                  <CustomMenuItem value={'2'}>
                    <Typography variant={'subtitle2'}>Q & dir</Typography>
                  </CustomMenuItem>
                </CustomSelect>
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'U (m/s)'}>
                <CustomInput disabled value={hydSourcePointsObjectInitialState.param1} maxWidth={'70px'} />
              </LabelWrapper>
            </Box>
            <Box>
              <LabelWrapper label={'V (m/s)'}>
                <CustomInput disabled value={hydSourcePointsObjectInitialState.param2} maxWidth={'70px'} />
              </LabelWrapper>
            </Box>
          </Box>
        </BoxComponent>
      );
    }

    const param1TypeLabel = inputState.source_points[selectedTab].parameter_type === 1 ? 'U (m/s)' : 'Q(m\u00B3/s)';
    const param2TypeLabel = inputState.source_points[selectedTab].parameter_type === 1 ? 'V (m/s)' : 'dir(deg)';

    return (
      <>
        <Box sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
          <Box>
            <LabelWrapper label={'Type'}>
              <CustomSelect
                required
                onChange={onDynamicSelectChange}
                value={inputState.source_points[selectedTab].point_type}
                name={'point_type'}
                type={'number'}
              >
                <CustomMenuItem value={0}>
                  <Typography variant={'subtitle2'}>Source</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={1}>
                  <Typography variant={'subtitle2'}>Sink</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={'Grid Cell'}>
              <Box sx={{ display: 'flex', gap: '16px 8px' }}>
                <CustomInputWithLabel
                  required
                  min={0}
                  errorText={'Enter positive number'}
                  onChange={onDynamicInputChange}
                  value={inputState.source_points[selectedTab].x}
                  type={'number'}
                  name={'x'}
                  label={'X-Axis'}
                />
                <CustomInputWithLabel
                  required
                  min={0}
                  errorText={'Enter positive number'}
                  onChange={onDynamicInputChange}
                  value={inputState.source_points[selectedTab].y}
                  type={'number'}
                  name={'y'}
                  label={'Y-Axis'}
                />
              </Box>
            </LabelWrapper>
          </Box>
        </Box>
        <Box mt={2} sx={{ display: 'flex', gap: '16px 24px', flexWrap: 'wrap' }}>
          <Box>
            <LabelWrapper label={'Type'}>
              <CustomSelect
                onChange={onDynamicSelectChange}
                value={inputState.source_points[selectedTab].parameter_type}
                name={'parameter_type'}
              >
                <CustomMenuItem value={'1'}>
                  <Typography variant={'subtitle2'}>U & V</Typography>
                </CustomMenuItem>
                <CustomMenuItem value={'2'}>
                  <Typography variant={'subtitle2'}>Q & dir</Typography>
                </CustomMenuItem>
              </CustomSelect>
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={param1TypeLabel}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter a number'}
                onChange={onDynamicInputChange}
                value={inputState.source_points[selectedTab].param1}
                type={'number'}
                name={'param1'}
                maxWidth={'70px'}
              />
            </LabelWrapper>
          </Box>
          <Box>
            <LabelWrapper label={param2TypeLabel}>
              <CustomInput
                required
                step={'any'}
                errorText={'Enter a number'}
                onChange={onDynamicInputChange}
                value={inputState.source_points[selectedTab].param2}
                type={'number'}
                name={'param2'}
                maxWidth={'70px'}
              />
            </LabelWrapper>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Box my={1}>
      <LabelWrapper label={'Number of discharge points'}>
        <CustomSelect
          onChange={onSelectChange}
          value={inputState.pointsLength}
          name={'pointsLength'}
          minWidth={'140px'}
          defaultValue={''}
          displayEmpty
        >
          {prepareSelectItems}
        </CustomSelect>
        <Box mt={2}>
          <CustomTabs value={selectedTab} onChange={onTabChange} variant={'scrollable'} scrollButtons={'auto'}>
            {sourceTabs}
          </CustomTabs>
        </Box>
        <Box>{preparePoints()}</Box>
      </LabelWrapper>
    </Box>
  );
};

export default memo(HydSourceSinksInputsGroup);
